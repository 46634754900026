.header-interval {
  margin-left: auto;
  display: flex;
  @media (max-width: 1140px) {
    display: block;
  }
  .ant-picker{
    margin-right: 10px;
    background: rgba(238, 242, 244, 0.5);
    &.hide{
      display: none;
    }
  }
  &__wrap {
    display: flex;
    justify-content: flex-start;
    position: relative;
    background-color: #e8eff5;
    //box-shadow:  3px 3px 6px #b6b5b5, -3px -3px 6px #ffffff;
    box-shadow: 3px 3px 6px #bbcfda, -3px -3px 6px white, inset 4px 4px 8px rgba(209,217,238,0.2), inset -8px -8px 8px rgba(255,255,255,.2);
    border-radius: 10px;
  }
  &__select {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    position: relative;
    background-color: #4ec0e4;
    border: none;
    border-radius: 10px;
    padding: 5px;
    color: white;
    min-height: 29px;
    option{
      background:#fff;
      color: black;
      padding: 10px;
    }
    @media (max-width: 768px) {
      width: 100%;
    }
  }
  &__item {
    padding: 8px 12px;
    font-size: 12px;
    line-height: 12px;
    font-weight: 300;
    cursor: pointer;
    outline: none;
    border: none;
    background-color: transparent;

    &:active {

    }

    &:active > span {
      display: block;
      //transform: scale(.95);
    }

    &--active {
      background: linear-gradient(145deg, #4ec0e4, #1a9aff);
      color: #fff;
    }

    span {
      transition: transform 300ms;
    }
    &:first-child {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
    &:last-child {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }
}
