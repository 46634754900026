.main-layout {
  //margin-left: 200px;
 // width: calc(100% - 200px);

  @media (max-width: 768px) {
    margin-left: 0;
    width: 100%;
  }

  &__content {
    width: 100%;
    padding-bottom: 40px;
    h1 {
      position: relative;
      display: inline-block;
      //margin: 10px 20px 20px;
      font-size: 24px;
      font-weight: 500;
      line-height: 29px;

      &::before {
        position: absolute;
        content: "";
        bottom: -6px;
        left: 0;
        width: 100%;
        height: 1px;
        background: #4ec0e4;
      }
      @media (max-width: 768px) {
        padding: 10px 10px 0;
        font-size: 20px;
        line-height: 20px;
      }
    }
  }

  &.scroll-lock {
    height: 100vh;
    overflow: hidden;
  }
}

.main-layout__mobile {
  display: none;

  @media (max-width: 768px) {
    display: flex;
    justify-content: space-between;
    padding: 10px;
  }
}
