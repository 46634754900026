.feed-filter {
  flex-shrink: 0;
  width: 340px;
  padding: 20px;
  background: white;
  margin: 20px;
  //margin-left: 0;
  border-radius: 10px;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
  margin-top: 38px;
  //margin-right: 35px;

  @media (max-width: 768px) {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
    width: 100%;
    padding-top: 60px;

    background-color: #fff;
  }

  &__close {
    display: none;

    @media (max-width: 768px) {
      display: inline-block;
      position: absolute;
      top: 15px;
      right: 16px;
      font-size: 20px;
    }
  }

  &__subtitle {
    font-size: 12px;
    color: #919399;
    margin-top: 7px;
    margin-bottom: 0;
  }
  &__selector {
    width: 100%;
  }
  &__source {
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  &.hide {
    display: none;
    @media (max-width: 768px) {
      display: none;
    }
  }
  .ant-checkbox-wrapper {
    width: 33%;
  }
  .ant-checkbox-wrapper.wide {
    margin-top: 10px;
    width: 100%;
  }
  .ant-checkbox-wrapper+.ant-checkbox-wrapper {
    margin-left: 0px;
  }
  .custom-select {
    max-width: 400px;
    min-width: 150px;
    width: 100%;
    margin-right: 5px;
  }
}

header{
    background: white;
    padding: 10px;
    margin: 20px;
    margin-left: 0;
    border-radius: 10px;
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
}

.source-list{
  display: flex;
  flex-wrap: wrap;
}

.source_avatar a{
  text-align: -webkit-center;
  width: 75px;
  overflow: hidden;
  display: block;
  margin: 0px 20px;
}

.source_img{
  box-sizing: border-box;
  display: block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  img{
    position: relative;
    top: -40px;
  }
}

.source_network{
  position: relative;
  top: 41px;
  left: 26px;
  z-index: 6;
}

.source_remove{
  height: 23px;
  width: 23px;
  border-radius: 50%;
  background: white;
  cursor: pointer;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
  left: 69px;
  position: relative;
  top: 19px;
  z-index: 6;
  display: flex;
  justify-content: center;
}

.time-range{
  width: 100%;
  margin: 10px 0;
}
