.lk-top-news {
  margin: 30px 0 0;

  @media (max-width: 768px) {
    padding: 0;
  }

  &__header {
    display: flex;
    align-items: flex-end;
    margin-bottom: 10px;
    //padding: 0 20px;

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: flex-start;
      padding: 0 10px;
    }
  }
  h2 {
    margin-bottom: 0;

    @media (max-width: 768px) {
      font-size: 20px;
      line-height: 20px;
    }
  }
  &__loader {
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    .spinner {
      transform: scale(0.7);
    }
  }
  &__list {
    //padding: 0 20px;
    margin-top: 10px;
    //padding-right: 55px;
    @media (max-width: 768px) {
      padding-right: 55px;
    }
  }
  &__empty {
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.lk-top-news-table {
  &__position {
    flex-shrink: 0;
    width: 55px;
  }
  &__theme {
    flex-grow: 1;
    text-align: left;
  }
  &__sources {
    flex-shrink: 0;
    width: 170px;
    text-align: center;

    @media (max-width: 768px) {
      width: auto;
    }
  }
  &__attendance {
    flex-shrink: 0;
    width: 170px;
    text-align: center;

    @media (max-width: 768px) {
      width: auto;
    }
  }
  &__tone {
    flex-shrink: 0;
    width: 170px;
    text-align: center;

    @media (max-width: 768px) {
      width: auto;
    }
  }
}

.lk-top-news-post {
  &__main {
    padding: 20px 0;
    flex-grow: 1;

    @media (max-width: 768px) {
      padding: 10px;
    }
  }
  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
}

.lk-top-news-post-source {
  text-decoration: none;
  color: inherit;
  display: flex;

  &__image {
    width: 40px;
    height: 40px;
    overflow: hidden;
    border-radius: 50%;
    margin-right: 15px;

    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }
  &__info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  &__title {
    font-weight: 300;
    font-size: 14px;
    margin-bottom: 5px;
    text-decoration: none;
    color: inherit;
    flex-shrink: 0;
  }
  &__date {
    font-weight: 300;
    font-size: 10px;
    text-decoration: none;
    color: inherit;
    flex-shrink: 0;
  }
}

.lk-top-news-post-info {
  display: flex;
  padding: 0;

  @media (max-width: 768px) {
    width: 100%;
    justify-content: space-between;
  }

  &__desc {
    display: none;

    @media (max-width: 768px) {
      display: block;
      margin-bottom: 2px;
      font-size: 10px;
      font-weight: 300;
      color: #919399;
    }
  }
  &__value {
    @media (max-width: 768px) {
      color: #313236;
      font-size: 14px;
      font-weight: 500;
      line-height: 16px;
    }
  }
}

.lk-top-news-post-body {
  display: flex;
  min-height: 90px;

  &__additional {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 5px;
    margin-right: 15px;
    text-align: center;
  }
  &__position {
    width: 40px;
    margin-top: 5px;
    font-size: 28px;
    font-weight: 500;
  }
  &__text {
    color: #333;
    font-weight: 300;
    line-height: 1.4;
    word-break: break-all;

    span {
      background-color: #ffcc00;
    }
  }
}

.lk-top-news-post-trust {
  position: relative;
  &:hover {
    .lk-top-news-post-trust__list {
      display: block;
    }
  }
  &__list {
    display: none;
    position: absolute;
    top: -37px;
    left: 8px;
    padding: 10px 0;
    background-color: #fff;
    border: 1px solid #ebeef5;
    border-radius: 2px;
  }

  &__item {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    padding: 0 10px;
    cursor: pointer;

    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }
}

.lk-top-news-post-footer {
  display: flex;
  font-weight: 500;
  font-size: 12px;
  color: #1f2f3d;
  padding: 15px 0 0 0;

  &__item {
    margin-right: 15px;
    cursor: pointer;
  }
}

.lk-social__list {
  @media (max-width: 768px) {
    padding-right: 55px;
  }
}
