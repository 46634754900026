.lk-subject {
  padding-bottom: 30px;

  &__header {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding: 10px 0px 10px;

    @media (max-width: 768px) {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 10px;
    }
  }
  &__loader {
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    .spinner {
      transform: scale(0.7);
    }
  }
  &__list {
    //padding: 0 20px;
    margin-top: 0;
    //padding-right: 55px;
    @media (max-width: 768px) {
      padding-right: 55px;
    }
  }
  &__empty {
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.feed-list-loader {
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  .spinner {
    transform: scale(0.7);
  }
}
.feed-list__empty {
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}
