.reference-source-rating {
  margin-top: 40px;
  &__subtitle {
    margin: 0;
  }
  &__header {
    //padding: 0 20px;
    display: flex;
    align-items: center;
  }
  &__wrap {
    //padding: 0 20px;
  }
}

.reference-source-rating-table {
  margin-top: 10px;

  &__head {}
  &__rating {
    width: 100px;
    text-align: center;
  }
  &__dynamic {
    width: 100px;
    text-align: center;
  }
  &__subjects {
    width: 400px;
  }
  &__count {
    width: 150px;
    text-align: center;
  }
  &__modify {
    width: 150px;
    text-align: center;
  }
  &__body {}
  &__row {}
}

.reference-source-rating-table-sources {
  padding-left: 0;
  padding-right: 0;
  text-align: center;
  &__title {}
  &__list {
    display: flex;
  }
  &__item {
    width: 80px;
    padding: 0 10px;
  }
}
