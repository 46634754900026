.feed-posts {
  flex-grow: 1;

  &__empty {
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.feed-event__header {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: 10px 0px 10px;
}

.post-list {
  padding: 20px 0;
  //padding-right: 55px;
  margin-top: 18px;
  @media (max-width: 768px) {
    padding-right: 55px;
  }
}

.feed-posts-loader {
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;

  .spinner {
    transform: scale(0.7);
  }
}

.feed {
  display: flex;
  align-items: flex-start;

  @media (max-width: 768px) {
    display: block;
  }
  header {
    margin: 0;
    padding: 3px 10px;
    position: absolute;
    right: 35px;
    .ant-checkbox {
      display: none;
    }
    &.header-delete {
      right: 94px;
      padding-right: 18px;
      padding-left: 18px;
      cursor: pointer;
      height: 22px;
      display: flex;
      align-items: end;
    }
    &.source-delete {
      right: 154px;
      padding-right: 18px;
      padding-left: 18px;
      cursor: pointer;
    }
  }
}

.feed-post-list {
  width: 100%;
  height: 100%;
  background-color: #efefef;
  list-style: none;
  margin: 0;
  padding: 20px 0;

  @media (max-width: 768px) {
    padding: 10px;
  }

  > li:not(:last-child) {
    margin-bottom: 20px;

    @media (max-width: 768px) {
      margin-bottom: 10px;
    }
  }
}

.feed-posts-pagination {
  padding: 20px 0;
  display: flex;
  justify-content: center;
  color: #333333;
  font-size: 12px;

  @media (max-width: 768px) {
    justify-content: center;
  }

  &__total {
    margin-left: auto;

    @media (max-width: 768px) {
      display: none;
    }
  }
  svg {
    fill: #4ec0e4;
  }
}

.ant-pagination-item a {
  color: #4ec0e4;
}

.ant-pagination-item-active {
  font-weight: 500;
  border-color: white;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
  border-radius: 7px;
}

.create-event-modal-form {
  &__theme {
  }
  &__label {
    color: #919399;
  }
  &__words {
    margin-top: 10px;
  }
  &__words-list {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      margin-right: 5px;
      margin-bottom: 3px;
      padding: 2px 4px;
      border: 1px solid #eeeeee;
      border-radius: 4px;

      span {
        opacity: 0.5;
        transition: opacity 0.2s linear;

        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
  &__add-word {
    margin-top: 10px;
  }
  &__row {
    display: flex;
    align-items: center;

    > span {
      color: #919399;
      margin-right: 10px;
    }
  }
  &__source {
    margin-top: 10px;
    //.row {
    //  display: flex;
    //  align-items: center;
    //
    //
    //}
  }
  &__retro {
    margin-top: 10px;
  }
}
