.feed-statistics {
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  // border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  // background-color: rgba(#7a11bb, 0.7);

  @media (max-width: 768px) {
    flex-wrap: wrap;
  }

  &-item {
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: 768px) {
      margin-bottom: 10px;
    }

    &:not(:last-child) {
      margin-right: 10px;
    }
  }
  &-item__value {
    font-size: 25px;
    font-weight: 500;
    line-height: 25px;
    color: #4ec0e4;
    // color: #fff;
  }
  &-item__desc {
    font-size: 14px;
    font-weight: 300;
    line-height: 10px;
    margin-top: 2px;
    color: #000;
    // color: #fff;
  }

  &__loader {
    height: 58px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    > div {
      transform: scale(0.5);
    }
  }
}
