.summary-digest {
  //padding: 0 20px;
  margin-top: 40px;
  &__subtitle {
    padding: 0;
    margin: 0;
  }
  &__header {
    display: flex;
    align-items: center;
    padding: 0;
    margin: 0;
  }
  &__list {
    .topics-list-row__theme .topics-list-row__text {
      cursor: pointer;
      transition: color 200ms;
      overflow: hidden;
      width: calc(100% + 5px);
      &:hover {
        //color: darken(#e8eff5, 40);
        color: #4ec0e4;
      }
      span{
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        line-height: 1.3em;
        word-break: break-word;
        //height: 3.9em;
      }
    }
  }
}
