.lk-stats {
  margin: 30px 0 0;

  &-flex{
    display: flex;
  }

  &__header {
    display: flex;
    align-items: flex-end;
    margin-bottom: 10px;
    //padding: 0 20px;

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: flex-start;
      padding: 0 10px;
    }
  }
  h2 {
    margin-bottom: 0;

    @media (max-width: 768px) {
      font-size: 20px;
      line-height: 20px;
    }
  }
}

.lk-stats-table {
  width: 370px;
  box-sizing: content-box;

  @media (max-width: 768px) {
    width: 100%;
  }

  &__body {
    width: 100%;
  }

  &__head {
    width: 100%;
  }
  &__name {
    flex-shrink: 0;
    width: 200px;

    @media (max-width: 768px) {
      width: 160px;
    }
  }
  &__values {
    display: flex;
    padding: 0;

    @media (max-width: 768px) {
      flex-direction: column;
      padding: 0;
    }
  }
  &__content {
    flex-shrink: 0;
    width: 170px;

    @media (max-width: 768px) {
      width: auto;
    }
  }

  &__desc {
    display: none;

    @media (max-width: 768px) {
      display: block;
      width: 60px;
      margin-right: 4px;
      font-size: 10px;
      font-weight: 300;
      color: #919399;
      text-align: left;
    }
  }

  &__value {
    @media (max-width: 768px) {

      color: #313236;
      font-size: 14px;
      font-weight: 500;
      line-height: 16px;
      text-align: left;
    }
  }
}

.table {
  //padding: 0 20px;

  @media (max-width: 768px) {
    margin-top: 20px;
    padding: 10px;
    background-color: #efefef;
  }

  &__head {
    display: flex;
    align-items: center;
    //background: #fafafa;
    border-bottom: 1px solid #f0f0f0;
    padding: 16px 0;

    @media (max-width: 768px) {
      display: none;
    }

    .table__col {
      color: rgba(0,0,0,.85);
      font-size: 14px;
      font-weight: 500;
      padding: 0 16px;

      &:not(:first-child) {
        //border-left: 1px solid rgba(0,0,0,.06);
      }
    }
  }

  &__body {
    background-color: #fff;
    padding-top: 0px;
    border-radius: 7px;
    border-top: 7px solid #76dbfb;
    border-bottom: 7px solid #76dbfb;
    box-shadow: 0px 8px 15px rgba(29, 29, 29, 0.1);

    &:nth-child(odd){
      background: #e8eff5;
    }
  }

  &__row {
    display: flex;
    align-items: center;
    margin: 0;
    //border-bottom: 1px solid #f0f0f0;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
        justify-content: space-between;
    color: #333;
    background: #e8eff5;
    border-bottom: 1px solid #4ec0e4;
    &:nth-of-type(odd){
      background: white;
    }

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: flex-start;
      border-radius: 4px;
      border: none;
      margin: 0;
    }

    &:not(:last-child) {
      @media (max-width: 768px) {
        margin-bottom: 10px;
      }
    }
  }

  &__col {
    font-size: 16px;
    line-height: 1.2;
    padding: 6px 16px;

    @media (max-width: 768px) {
      padding: 5px 10px;
    }
  }

  &__loader {
    height: 150px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #efefef;

    > div {
      transform: scale(0.7);
    }
  }

  &__empty {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #919399;
    font-weight: 300;
    height: 70px;
  }
}


.lk-stats {
  margin: 30px 0 0;
  padding-bottom: 30px;

  &__header {
    display: flex;
    align-items: flex-end;
    margin-bottom: 10px;
    //padding: 0 20px;

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: flex-start;
      padding: 0 10px;
    }
  }
  h2 {
    margin-bottom: 0;

    @media (max-width: 768px) {
      font-size: 20px;
      line-height: 20px;
    }
  }
}

.lk-stats-flex{
  display: flex;
  @media (max-width: 768px) {
    display: block;
  }
}

.full-graph{
  display: block;
}

.full-graph .lk-stats-graph {
  width: 100%;
}

.full-graph .lk-stats-flex-table{
  display: flex;
}

.lk-stats-graph_after{
  display: flex;
  justify-content: center;
}

.lk-stats_flex{
  display: flex;
  align-items: baseline;
}

#neutral {
	width: 10px;
	height: 10px;
	background: #d6dfe6;
  margin-right: 10px;
  margin-left: 15px;
}

#positive {
	width: 10px;
	height: 10px;
	background: #a2e780;
  margin-right: 10px;
  margin-left: 15px;
}

#negative {
	width: 10px;
	height: 10px;
	background: #fdab96;
  margin-right: 10px;
  margin-left: 15px;
}

#likes {
	width: 10px;
	height: 10px;
	background: #c5e8e4;
  margin-right: 10px;
  margin-left: 15px;
}

#reposts {
	width: 10px;
	height: 10px;
	background: #91e0f9;
  margin-right: 10px;
  margin-left: 15px;
}

.lk-stats-graph{
  width: 65%;
  @media (max-width: 768px) {
    width: 100%;
  }
}

.lk-stats-table {
  box-sizing: content-box;

  @media (max-width: 768px) {
    box-sizing: border-box;
  }

  &.table {
    @media (max-width: 768px) {
      box-sizing: border-box;
      margin-top: 0;
      padding-top: 0;
    }
  }

  &--smi {
    width: 370px;

    @media (max-width: 768px) {
      width: 100%;
    }

    .table__row {
      @media (max-width: 768px) {
        flex-direction: row;
        align-items: center;
      }
    }

    .table__col {
      @media (max-width: 768px) {
        width: auto;
        padding: 0;
      }
    }
    .lk-stats-table__name {
      @media (max-width: 768px) {
        margin-right: 10px;
      }
    }
  }
  &--social {
    width: 710px;

    @media (max-width: 768px) {
      width: 100%;
    }
    .lk-stats-table__values {
      //padding-left: 20px;
    }
    .table__col {
      @media (max-width: 768px) {
        display: flex;
        align-items: center;
        padding: 3px 0;
      }
    }
  }

  &__body {
    width: 100%;
  }

  &__head {
    width: 100%;
  }

  &__row {

  }
  &__name {
    flex-shrink: 0;
    width: 200px;

    @media (max-width: 768px) {
      width: 160px;
    }
  }
  &__values {
    display: flex;
    padding: 0;

    @media (max-width: 768px) {
      flex-direction: column;
      padding: 0;
    }
  }
  &__content {
    flex-shrink: 0;
    width: 170px;
    text-align: right;

    @media (max-width: 768px) {
      width: auto;
    }
  }

  &__desc {
    display: none;

    @media (max-width: 768px) {
      display: block;
      width: 60px;
      margin-right: 4px;
      font-size: 10px;
      font-weight: 300;
      color: #919399;
      text-align: left;
    }
  }

  &__value {
    @media (max-width: 768px) {

      color: #313236;
      font-size: 14px;
      font-weight: 500;
      line-height: 16px;
      text-align: left;
    }
  }
}

