.summary-topics-week {
  ////padding: 0 20px;
  margin-top: 40px;
  &__subtitle {
    padding: 0;
    margin: 0;
  }
  &__header {
    display: flex;
    align-items: center;
    padding: 0;
    margin: 0;
  }
  &__list {
    .topics-list-row__theme .topics-list-row__text {
      cursor: pointer;
      transition: color 200ms;
      overflow: hidden;
      //height: 63px;
      width: calc(100% + 5px);
      padding-right: 10px;
      &:hover {
        //color: darken(#e8eff5, 40);
        color: #4ec0e4;
      }
      span{
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        line-height: 1.3em;
        word-break: break-word;
        //height: 3.9em;
      }
    }
    .topics-list-row__subject {
      width: 200px;
    }
  }
}

.topics-list, .statistic-list {
  margin-top: 10px;
  &__loader,
  &__empty {
    height: 80px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.topics-list-row, .statistic-list-row {
  background: #fff;
  background-position: 0;
  background-repeat: no-repeat;
  margin: 10px 0;
  border-radius: 10px;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
  
  &:first-child {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  &__wrap {
    position: relative;
    display: flex;
    overflow: hidden;
    padding: 15px 20px 15px 0;

    @media (max-width: 768px) {
      display: block;
      padding-left: 20px;
    }
  }
  &__rating {
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 0 5px 2px;

    width: 74px;
    font-size: 50px;
    line-height: 1;
    font-weight: 300;
    color: #4ec0e4;
  }
  &__subject {
    position: relative;
    z-index: 2;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    margin-right: 15px;

  }
  &__theme {
    position: relative;
    z-index: 2;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-right: 20px;
    //padding: 15px 15px 15px 0;
  }
  &__label {
    margin-bottom: 3px;
    font-size: 16px;
    line-height: 1;
    color: #4ec0e4;
  }
  &__text {
    font-size: 16px;
    line-height: 1.2;
    font-weight: 500;
    color: #333;
  }
  &__chart {
    flex-shrink: 0;
    width: 270px;
    //padding: 15px 15px 15px 0;
    margin-right: 20px;
  }
  canvas{
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 10px;
  }

}

.topics-list-row-info {
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  margin-top: 8px;
  sup {
    color: gray;
    top: -6px;
    font-size: 16px;
    &.up {
      color: green;
    }
    &.down {
      color: red;
    }
  }
  &__value {
    font-size: 20px;

    font-weight: 500;
    color: #4ec0e4;
    margin-right: 10px;
  }
  .topics-list-row__label, .statistic-list-row__label {
    opacity: 1;
    //width: 40%;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: black;
    margin-top: 0px;
    &:not(:last-child) {
      margin-right: 30px;
    }
  }
}

.statistic-list-row-info {
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
  margin-top: 8px;
  sup {
    color: gray;
    top: -6px;
    font-size: 16px;
    &.up {
      color: green;
    }
    &.down {
      color: red;
    }
  }
  &__value {
    font-size: 20px;

    font-weight: 500;
    color: #4ec0e4;
    margin-right: 3px;
  }
  .topics-list-row__label, .statistic-list-row__label {
    opacity: 1;
    //width: 40%;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: black;
    margin-top: 0px;
    &:not(:last-child) {
      margin-right: 10px;
    }
  }
}

.topics-modal__info .topics-list-row__label {
    margin-bottom: 3px;
    font-size: 16px;
    line-height: 1;
    color: black;
    .topics-list-row-info__value {
      font-size: 20px;
      font-weight: 500;
      color: #4ec0e4;
      margin-right: 5px;
      margin-left: 7px;
  }
}

.summary-digest .topics-list-row__subject{
width: 200px;
}

