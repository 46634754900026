.summary-topics {
  ////padding: 0 20px;
  //margin-top: 40px;

  @media (max-width: 768px) {
    //padding: 0 10px;
    margin: 40px 0 0;
  }

  &__header {
    display: flex;
    align-items: flex-end;
    margin-bottom: 10px;
    padding: 0;
    margin-top: 10px;

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: flex-start;
      padding: 0;
      margin-bottom: 0;
      margin-top: 0;
    }
  }

  &__list {
    .topics-list-row__text {
      cursor: pointer;
      transition: color 200ms;
      overflow: hidden;
      height: 22px;
      width: calc(100% + 5px);
      &:hover {
        //color: darken(#e8eff5, 40);
        color: #4ec0e4;
      }
      span{
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        line-height: 1.3em;
        height: 3.9em;
        word-break: break-word;
      }
    }
  }
}
.topics-modal-parent {
  @media (max-width: 768px) {
    top: 40px;
    bottom: 5px;
    height: calc(100vh - 50px);
    box-sizing: border-box;
  }
  .ant-modal-body {
    //background-color: #e8eff5;

    @media (max-width: 768px) {
      height: 100%;
      padding: 10px 5px 10px 10px;
    }
  }
  .ant-modal-content {
    @media (max-width: 768px) {
      height: 100%;
    }

    .ant-modal-close {
      @media (max-width: 768px) {
        top: -50px;
        right: -10px;
        color: #fff;
      }
    }
  }
}
.topics-modal {
  height: 70vh;
  box-sizing: border-box;

  @media (max-width: 768px) {
    height: 100%;
  }
  &__wrap {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  &__loading {
    height: 300px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    > {
      transform: scale(0.5);
    }
  }
  &__empty {
    height: 300px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__header {
    flex-shrink: 0;
    padding-bottom: 10px;
  }
  &__list {
    flex-grow: 1;
    padding-right: 25px;
    overflow: auto;
  }
  .topics-list-row__label {
    @media (max-width: 768px) {
      font-size: 14px;
    }
  }
}
