.summary-topics {
  ////padding: 0 20px;
  margin-top: 15px;

  &__header {
    display: flex;
    align-items: center;
  }

  &__list {
    .topics-list-row__text {
      cursor: pointer;
      transition: color 200ms;
      overflow: hidden;
      height: 22px;
      width: calc(100% + 5px);
      &:hover {
        //color: darken(#e8eff5, 40);
        color: #4ec0e4;
      }
      span{
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        line-height: 1.3em;
        height: 3.9em;
        word-break: break-word;
      }
    }
  }
}

.topics-modal-parent {
  .ant-modal-body {
    //background-color: #e8eff5;
  }
}
.topics-modal {
  height: 70vh;
  box-sizing: border-box;
  &__wrap {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  &__loading {
    height: 300px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    > {
      transform: scale(0.5);
    }
  }
  &__empty {
    height: 300px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__header {
    flex-shrink: 0;
    padding-bottom: 10px;
  }
  &__list {
    flex-grow: 1;
    padding-right: 25px;
    overflow: auto;
  }
  &__mentioning {
    display: flex;
    align-items: center;
    margin-top: 10px;
    cursor: pointer;
    .checkbox {
      width: 24px;
      height: 24px;
      border-radius: 4px;
      background-color: #e8eff5;
      box-shadow: 1px 1px 4px #bbcfda, -1px -1px 4px white;
      &.active {
        box-shadow: 3px 3px 6px #bbcfda, -3px -3px 6px white;
        background: linear-gradient(145deg, #1890ff, darken(#1890ff, 6));
        position: relative;
        &::before {
          content: "";
          position: absolute;
          top: 3px;
          width: 25px;
          height: 10px;
          border-right: 4px solid #fff;
          border-bottom: 4px solid #fff;
          transform: rotate(45deg);
          transform: scale(-1, 1) rotate(45deg);
        }
      }
    }
    span {
      display: block;
      margin-left: 10px;
    }
  }
}
