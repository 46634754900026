.header-reports {
  flex-shrink: 0;
  display: flex;
  margin-left: auto;

  @media (max-width: 768px) {
    flex-wrap: wrap;
    margin-top: 10px;
  }

  &__item {
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #333;
    cursor: pointer;
    padding: 0px 5px;
    border-radius: 10px;

    @media (max-width: 768px) {
      margin-top: 10px;
    }

    &:not(:last-child) {
      margin-right: 20px;
    }

    &.work {
      color: rgba(0, 0, 0, 0.25);
      cursor: not-allowed;

      &:hover {
        color: rgba(0, 0, 0, 0.25);
      }
    }
  }

  &__label {
    margin-left: 3px;
    font-size: 12px;
    font-weight: 100;
    color: #333;
  }
}

.ant-btn-primary:focus .header-reports__label, .ant-btn-primary:hover .header-reports__label{
  color: #fff;
}

.feed-report-modal {
  &__item {
    &:not(:last-child) {
      margin-bottom: 15px;
    }
  }
  &__label {
    margin-bottom: 3px;
    font-size: 14px;
    color: #919399;
  }
}

@media (max-width: 768px) {
  .feed-event__header {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 10px;
  }
}
