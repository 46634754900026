.change-tonal-request-modal {
  height: 200px;
  &__parent {
    .ant-modal-content {
      background-color: #e8eff5;
      overflow: hidden;
    }
    .ant-modal-body {
      padding: 0;
    }
    .ant-modal-footer,
    .ant-modal-header {
      background-color: #e8eff5;
      border-color: darken(#e8eff5, 1);
    }
    .ant-modal-footer {
      //box-shadow: 0 -2px 4px white;
    }
    .ant-modal-header {
      //box-shadow: 0 2px 4px #bbcfda;
    }
  }
  &__loading {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 300px;
    height: 100%;
    .spinner {
      transform: scale(0.5);
    }
  }
  &__wrap {
    width: 200px;
    height: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  &__item {
    width: 100%;
    text-align: center;
    padding: 6px 0;
    cursor: pointer;
    border-radius: 4px;
    box-shadow: 1px 1px 4px #bbcfda, -1px -1px 4px white;
    &:not(:last-child) {
      margin-bottom: 10px;
    }
    &.active {
      color: #fff;
      background: linear-gradient(145deg, #d6dfe6, darken(#d6dfe6, 6));
      box-shadow: 3px 3px 6px #bbcfda, -3px -3px 6px white;
      &.positive {
        background: linear-gradient(145deg, #a2e780, darken(#a2e780, 6));
      }
      &.negative {
        background: linear-gradient(145deg, #fdab96, darken(#fdab96, 6));
      }
    }
  }
  textarea {
    margin-top: 10px;
    border-radius: 4px;
    box-shadow: 1px 1px 4px #bbcfda, -1px -1px 4px white;
    resize: none;
  }
}
