.user-placeholder {
  display: block;
  width: 100%;
  circle {
    fill: #e5e5e5;
  }
  path {
    fill: #fff;
  }
}
